:root {
  --color-primary-100: #f6f8fe;
  --color-primary-500: #200df2;

  --color-secondary-100: #fbfaf9;
  --color-secondary-200: #f1edea;
  --color-secondary-300: #e7dfda;
  --color-secondary-500: #7e7367;
  --color-secondary-900: #0f0d0a;

  --color-white: #ffffff;
}

html,
body {
  height: 100%;
  min-height: 100%;
  position: relative;
  overflow: hidden !important;
}

/* stylelint-disable-next-line selector-id-pattern */
#__next {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

/* stylelint-disable-next-line selector-id-pattern */
#__main {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.serif {
  font-family: var(--font-serif);
}

.navbar {
  z-index: 96;
  height: 5rem;
}

.filter-icon {
  width: 1rem;
  height: 1rem;
}
.description-body {
  .h4 {
    font-size: 16px;
    font-weight: 500;
    padding: 1rem 0;
  }
  p:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  p,
  li {
    color: var(--color-secondary-500);
  }
}
.description-intro {
  line-height: 1.5;
  font-size: 16px;
  color: var(--color-secondary-500);
  p:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  p {
    color: var(--color-secondary-500);
  }
}
.agency-cta {
  border-width: 2px !important;
}

.review-star {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.page-button {
  width: 2rem;
  height: 2rem;
}
.agency-card-content {
  transition: all 0.1s ease-in-out;

  &:hover {
    .flag {
      filter: none;
    }
  }
}
.agency-card-content:hover {
  border: 1px solid var(--color-secondary-900);
}
.agency-card-avatar {
  border: 2px solid var(--color-white);
  border-image: none;
  object-fit: contain;
}
.divider {
  border: none;
  height: 1px;
  background-color: var(--color-secondary-300);
  padding: 0;
  margin: 0;
}
.top-link-anchor {
  color: var(--color-secondary-500);
}
.top-link-anchor:hover {
  color: var(--color-primary-500) !important;
}

.company-logo {
  filter: invert(63%) sepia(3%) saturate(13%) hue-rotate(315deg) brightness(87%) contrast(94%);
}

.hero-title {
  line-height: 5rem;
  font-size: 5rem;
}

@media only screen and (max-width: 600px) {
  .grid-list {
    grid-template-columns: 100% !important;
  }
  .filter-icon {
    width: 1.25rem;
    height: 1.25rem;
  }
  .hero-title {
    overflow-wrap: break-word;
  }

  .hero {
    min-height: calc(100vh - 3.5rem);
  }
}
@media only screen and (max-width: 960px) {
  .navbar {
    height: 3.5rem;
  }
  .hero-title {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

@media only screen and (min-width: 960px) {
  .hero-full-viewport {
    min-height: calc(100vh - 3.5rem);
  }
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.menu-fix-content {
  min-width: max-content;
}

.menu {
  white-space: nowrap;
  margin-top: 4px;
  transform-origin: 0px 0px;
  animation: fadeInScale 200ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  overflow: auto;
  max-height: 500px;
}

@keyframes slideInTop {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.find-agencies-menu {
  z-index: 94;
  min-width: 100%;
  left: 0;
  top: 5rem;
  animation-duration: 0.2s;
  animation-name: slideInTop;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  transform: translateY(-10%);
}

.section-title:hover,
.section-title.active {
  background-color: var(--color-primary-100);

  h6,
  p {
    color: var(--color-primary-500) !important;
  }

  .arrow-forward {
    display: block !important;
  }
}

.expertise-list {
  max-height: 24rem;
  overflow: hidden;
}

.expertise-list:hover {
  overflow: auto;
}

.description-and-socials {
  max-width: 25rem;
}

.social-icon {
  opacity: 0.65;
}

.agencies-list-wrapper:last-child {
  margin-left: 1rem;
  @media only screen and (max-width: 960px) {
    margin-left: 0;
  }
}

.other-links:hover {
  color: var(--color-primary-500) !important;
}

.top-links-anchor:hover {
  color: var(--color-primary-500) !important;
}

.top-links-button {
  background-color: transparent;
  border: none;
  font-size: 1.375rem;
  line-height: 2rem;
  transition: all 0.3s ease-in-out;
  color: var(--color-secondary-300);

  @media only screen and (min-width: 600px) {
    font-size: 2.375rem;
    line-height: 2.85rem;
  }
}

.top-links-button:hover {
  transition: all 0.3s ease-in-out;
  color: var(--color-secondary-500);
}

.top-links-button-active {
  color: var(--color-secondary-900);
}

.top-links-wrapper:-webkit-scrollbar {
  display: none;
}

.mobile-menu {
  top: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  min-height: 100%;
  pointer-events: auto;
  transition: all 0.2s ease-in-out;
  transform: translateX(-100%);
  transform-origin: top;

  &.opened {
    transition: all 0.2s ease-in-out;
    transform: translateX(0);
  }
}

.provider-link {
  &:hover,
  &.active {
    color: var(--color-primary-500) !important;
    background-color: var(--color-primary-100);

    .arrow-forward {
      display: block !important;
    }
  }
}

.flag-absolute {
  position: absolute;
  top: 0;
  left: 1rem;
}

.flag {
  filter: grayscale(120%) contrast(100%);
}

.sort-menu-item {
  &:hover {
    background-color: var(--color-primary-100);
    color: var(--color-primary-500);
  }
  padding: 8px !important;
}

.sort-menu-item-new {
  &:hover {
    background-color: var(--color-secondary-200);
    color: var(--color-secondary-900);
  }
  padding: 8px !important;
}

.sort-menu {
  right: 0;
  min-width: 250px;
}

.menu-item {
  line-height: 1.5;

  color: inherit;
  cursor: pointer;
  &:hover {
    background-color: var(--color-secondary-100);
    color: var(--color-secondary-900) !important;
  }

  a {
    color: #463f39;
    cursor: pointer;
    &:hover {
      background-color: var(--color-secondary-100);
      color: var(--color-secondary-900);
    }
  }
}

.domains-menu {
  top: -410px;
  right: 0;
  max-height: 400px;
  overflow-y: scroll;

  @media only screen and (max-width: 960px) {
    left: 0px;
    width: 100%;
  }
}

.subtitle {
  @media only screen and (min-width: 960px) {
    position: absolute;
    top: 24px;
  }
}

.agency-cta-bottom {
  display: block;
}

.agencies-card-layout {
  @media only screen and (min-width: 600px) and (max-width: 1280px) {
    .grid-list {
      grid-template-columns: repeat(2, 1fr) !important;
    }
  }
  .agency-card-compare-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .flags {
    position: absolute;
    top: 0;
    left: 1rem;
  }

  .agency-badges {
    justify-content: center;
  }

  .grid-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .agency-name {
    padding: 0 1em;
  }

  .agency-main {
    padding: 1rem 1.5rem;
  }

  .agency-main-info {
    display: flex;
    flex-direction: column;
    min-height: 250px;
    max-height: 250px;
  }

  .agency-info-hide-card {
    display: none;
  }

  .agency-info-cell {
    min-width: 100% !important;
  }
}

.agencies-horizontal-layout {
  .agency-card-compare-button {
    @media only screen and (max-width: 600px) {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .agency-info-hide-card {
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .flags {
    @media only screen and (max-width: 600px) {
      position: absolute;
      top: 0;
      left: 1rem;
    }
  }

  .grid-list {
    display: grid;
    grid-template-columns: 100%;
  }

  .agency-badges {
    justify-content: center;

    @media only screen and (min-width: 600px) {
      justify-content: start;
    }
  }

  .agency-info {
    @media only screen and (min-width: 600px) {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .agency-name {
    flex: 1;
    max-width: 70%;
    @media only screen and (min-width: 600px) {
      justify-content: start;
      align-items: start;
    }
  }

  .agency-logo {
    @media only screen and (min-width: 600px) {
      margin: 0 20px;
    }
  }

  .agency-rating {
    @media only screen and (min-width: 600px) {
      justify-content: start;
      align-items: center;
    }
  }

  .agency-main {
    padding: 1rem 1rem 1rem 7.5rem;
    @media only screen and (max-width: 600px) {
      padding: 1rem 1.5rem;
    }
  }

  .agency-main-info {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
  }

  .agency-info-cell {
    flex: 1 1 100%;
    @media only screen and (min-width: 600px) {
      max-width: 30% !important;
    }
  }

  .agency-card-cta {
    @media only screen and (min-width: 600px) {
      position: absolute !important;
      top: 1rem;
      right: 1rem;
      max-width: fit-content;

      > button {
        color: var(--color-primary-500) !important;
        background: transparent !important;
        border: 1px solid #bac4f7 !important;
      }
    }
  }
}

.work-card-thumbnail {
  height: 14rem;
  min-height: 14rem;
  min-width: 100%;

  > img {
    object-fit: cover;
  }
}

.keyword-selector {
  div[class*='-control'] {
    background-color: var(--color-secondary-100);
    border-radius: 0.375rem;
    padding: 0 12px 0 16px;
    cursor: text !important;
    box-shadow: none;
  }

  input,
  div[class*='-singleValue'],
  div[class*='-placeholder'] {
    font-size: 14px !important;
  }
}

.search-cta {
  .react-select__control {
    cursor: text;
  }
  .react-select__value-container {
    padding: 0;
    font-size: 14px;
  }
}

.subnav-item {
  outline: none;
  border: none;
  background-color: var(--color-secondary-100);

  &:hover {
    span,
    svg {
      color: var(--color-secondary-900);
    }
    border-bottom: 3px solid var(--color-secondary-900) !important;
  }
}

.subnav-menu {
  display: none;
  box-shadow:
    0 4px 2px -2px rgba(15, 13, 10, 0.1),
    0 0px 20px rgba(15, 13, 10, 0.08);

  &.slide-in-top-subnav {
    display: flex;
    animation-duration: 0.2s;
    animation-name: slideInTopSubnav;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    transform: translateY(-10%);
  }

  @keyframes slideInTopSubnav {
    0% {
      opacity: 0;
      transform: translateY(-10%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  a {
    &:hover {
      color: var(--color-secondary-900);
    }
  }
}

#project-new {
  .grw-net-widget {
    font-family: var(--font-sans-serif);
  }

  .branding {
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
  }
}
.search-service-select {
  .react-select__control {
    background-color: var(--color-secondary-100);
  }

  .react-select__placeholder {
    color: var(--color-secondary-700);
    opacity: 0.5;
    padding: 1px 3px !important;
  }
}

.drawer-filters {
  @media only screen and (min-width: 480px) {
    width: 480px;
  }
}

.filters-bar {
  position: sticky;
  top: 0;
  z-index: 70;
}
